import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headereducation"

const EducationSystem = () => (
  <Layout>
    <SEO title="Education System" />

    <Header />
    <section className="section bg-primary">
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col">
            <h1 className="h2 my-2 font-weight-bold text-uppercase text-light">
              Invest in our education sector
            </h1>
          </div>
          <div className="col-auto">
            <a className="btn btn-sm btn-outline-light">
              Where can you invest?
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <h5 className="h3 font-weight-bold">EDUCATION SYSTEM</h5>
        <div className="py-8">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-7 order-lg-1">
              <p className="h3 font-weight-bold">
                THE EDUCATION SYSTEM IN RWANDA
              </p>
              <p className=" my-4">
                For investors who are willing to set up schools in Rwanda, you
                need to work with The Ministry of Education in Rwanda to ensure
                you meet the required criteria.
              </p>
              <p className="my-4">
                Over the years, Rwanda’s education system has experienced
                tremendous growth. Access to quality education has been a
                critical aspect of the government’s mandate in the education
                sector. Various bodies oversee the education standards offered
                in Rwanda.
              </p>
              <p className="my-4">
                The bodies monitor the inspectorate of education, standards on
                system performance, advises on education standards, as well as
                ensuring compliance and adherence. This body also oversee other
                critical factors such as standards of classrooms, school
                construction, standard equipment, and department planning and
                sets standards for grades.
              </p>
            </div>
            <div className="col-lg-5 order-lg-2">
              <div className="card mb-0 ">
                <div className="card-body p-2">
                <img
                    alt="Image placeholder"
                    src={require("../images/49035306522_c05f7d65aa_o.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-7 order-lg-2">
              <h5 className="h3 font-weight-bold">
                FEATURES OF RWANDA EDUCATION SECTOR PROFILE
              </h5>
              <p className=" my-4">
                The normal education system comprises of 4 levels and operates
                on 6-3-3-4 system. This means learners undergo 6 years of
                primary school, three years of junior secondary school, 3 years
                of senior education, and 4 years for University Bachelors
                degree. Every year over 44,000, students are enrolled to
                institutions of higher learning. Rwanda has a mixture of public
                and private institutions.
              </p>
            </div>
            <div className="col-lg-5 order-lg-1">
              <div className="card mb-0 mr-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/49035254257_378ca7389f_o.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h5 className="h3 font-weight-bold text-center">
                The Ministry of Education is a government body whose core
                functions are:
              </h5>
              <p className="my-4">
                <ul>
                  <li>
                    Developing, reviewing and guiding the implementation of
                    education sector policies and strategies geared towards
                    achieving vision 2020 through the elaboration, dissemination
                    and coordination of the implementation of education sector
                    policies, strategies and programs regarding basic education,
                    post basic and higher education, literacy, special programs
                    and information and communication technology in education
                  </li>
                  <li>
                    Enacting laws and adopting regulations and guidelines for
                    the promotion of education in line with national education
                    policy through:
                    <ul>
                      <li>
                        Drafting up laws, regulations and guidelines for the
                        promotion of education
                      </li>
                      <li>
                        Setting up and disseminating standard norms applicable
                        to education sector
                      </li>
                    </ul>
                  </li>
                  <li>
                    Developing and managing Education Monitoring and Evaluation
                    systems trough:
                    <ul>
                      <li>
                        monitoring and evaluation of education policies,
                        strategies and programs
                      </li>
                      <li>
                        monitoring the education indicators and consolidate the
                        data handled by decentralized entities
                      </li>
                      <li>
                        reporting periodically and annually to the Cabinet on
                        the impact of the education programs, strategies and
                        programs on the development of the education sector
                      </li>
                    </ul>
                  </li>
                  <li>
                    Developing strategies for resource mobilization and
                    efficient utilization through:
                    <ul>
                      <li>
                        mobilizing resources and supervision of its use to
                        ensure rational utilization for the development of
                        education
                      </li>
                      <li>
                        promoting partnership with private investment in the
                        development of education.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Promoting the teaching, learning, and the good use of
                    Information and Communication in Education through:
                    <ul>
                      <li>
                        promoting information and technologies in the area of
                        education
                      </li>
                      <li>
                        promoting information and communication technology as a
                        tool for learning and teaching
                      </li>
                    </ul>
                  </li>
                  <li>
                    Developing, reviewing and guiding education sector for
                    institutional development and capacity building through:
                    <ul>
                      <li>
                        overseeing the programs of school construction and
                        equipment;
                      </li>
                      <li>
                        overseeing formal education and continuing training of
                        teaching staff and other staff of the Ministry and its
                        agencies.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
              <p className="my-4">
                For more information visit:{" "}
                <a
                  href="https://mineduc.gov.rw/index.php?id=42"
                  className="btn-link"
                >
                  https://mineduc.gov.rw/index.php?id=42
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default EducationSystem
